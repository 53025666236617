<!-- 车主用钱 -->
<template>
  <div class="UsingMoney">
    <iframe
      :src="`${$yxPath.carOwnerUsingMoney}`"
      frameborder="0"
      class="embeddedPage"
    ></iframe>
    <Footer :active="1" />
  </div>
</template>

<script>
export default {
  name: "UsingMoney"
};
</script>
<style lang="scss" scoped>
.UsingMoney {
  height: 100%;
  padding-bottom: 0.5rem;
  .embeddedPage {
    width: 100%;
    height: 100%;
  }
}
</style>
